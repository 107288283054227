<template>
  <div>
    <v-container>
      <Pds7 />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    Pds7: () => import("@/components/pds7"),
  },
};
</script>